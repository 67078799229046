@charset "UTF-8";
/*
 * Web Starter Kit
 *
 * Multi-screen layout styles for your page. Brought to you by the
 * Web Starter Kit team.
 *
 */
/*
 * HTML5 Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
html,
button,
input,
select,
textarea {
  color: #222; }

body {
  font-size: 1em;
  line-height: 1.4; }

a {
  color: #00e; }

a:visited {
  color: #551a8b; }

a:hover {
  color: #06e; }

/*
 * Remove the gap between images and the bottom of their containers: h5bp.com/i/440
 */
img {
  vertical-align: middle; }

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical; }

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
/* ==========================================================================
   Helper classes
   ========================================================================== */
/* Prevent callout */
.nocallout {
  -webkit-touch-callout: none; }

.pressed {
  background-color: rgba(0, 0, 0, 0.7); }

/* A hack for HTML5 contenteditable attribute on mobile */
textarea[contenteditable] {
  -webkit-appearance: none; }

/* A workaround for S60 3.x and 5.0 devices which do not animated gif images if
   they have been set as display: none */
.gifhidden {
  position: absolute;
  left: -100%; }

/*
 * Image replacement
 */
.ir {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em; }

.ir br {
  display: none; }

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.hidden {
  display: none !important;
  visibility: hidden; }

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/*
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/*
 * Hide visually and from screenreaders, but maintain layout
 */
.invisible {
  visibility: hidden; }

/**
 * Clearfix helper
 * Used to contain floats: h5bp.com/q
 */
.clearfix::before,
.clearfix::after {
  content: "";
  display: table; }

.clearfix::after {
  clear: both; }

/**
 * Wadaya website version 3
 * application stylesheets
 *
 */
body {
  font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
  color: #292929; }
  body a {
    color: #292929; }
    body a:visited {
      color: #292929; }
    body a:hover {
      color: #cf121b; }

body.b-bettei {
  background-color: #1b1515;
  color: #f0eeee; }
  body.b-bettei a {
    color: #f0eeee; }
    body.b-bettei a:visited {
      color: #f0eeee; }
    body.b-bettei a:hover {
      color: #cf121b; }

main {
  display: block; }

.b-bettei .l-section > .l-section-head {
  border-top: solid 1px #f0eeee;
  border-bottom: solid 1px #f0eeee; }

.l-header {
  position: fixed;
  z-index: 100;
  box-sizing: border-box;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8); }
  .l-header .l-header-nav {
    height: 70px; }
  .l-header .c-logo {
    display: block;
    float: left;
    width: auto;
    height: 70px;
    margin-left: 27px;
    line-height: 68px; }
    .l-header .c-logo img {
      width: auto;
      height: 47px; }
  .l-header .c-nav-list {
    float: right;
    margin: 0;
    line-height: 70px; }
    .l-header .c-nav-list > li {
      display: inline-block;
      list-style-type: none; }
    .l-header .c-nav-list > li a {
      display: inline-block;
      height: 70px;
      line-height: 70px;
      color: #292929;
      text-decoration: none; }
    .l-header .c-nav-list > li a:visited {
      color: #292929; }
    .l-header .c-nav-list > li a:hover {
      color: #cf121b;
      text-decoration: underline; }
    .l-header .c-nav-list > li:before {
      display: inline-block;
      content: ' ';
      width: 6px;
      height: 11px;
      padding-left: 6px;
      background-image: url("../images/components/c-nav-list_arrow.png");
      background-size: 6px 11px;
      background-repeat: no-repeat; }
    .l-header .c-nav-list > li.s-nav-list-red:before {
      background-image: url("../images/components/c-nav-list_arrow_r.png"); }
    .l-header .c-nav-list > li.c-nav-list-language:before {
      display: none; }
    .l-header .c-nav-list > li + li {
      margin-left: 40px; }
    .l-header .c-nav-list > li + li:last-child {
      margin-right: 40px; }
  .l-header .c-nav-list-menu {
    display: none; }
  .l-header .c-nav-list-reservation {
    padding: 0; }

.l-main {
  padding-top: 72px; }

.l-main-container {
  padding-bottom: 60px; }

.l-main-container + .l-main-container {
  padding-top: 60px; }

.l-main-container.l-main-container-secondary {
  background-color: #f2f2f2;
  padding-bottom: 60px; }
  .l-main-container.l-main-container-secondary .l-section > .l-section-head {
    border: none;
    padding: 0; }
  .l-main-container.l-main-container-secondary .l-section > .l-section-item + a[name] + .l-section-item {
    border-top: solid 1px #fff;
    margin-top: 60px;
    padding-top: 60px; }
  .l-main-container.l-main-container-secondary .l-section h2 {
    font-weight: normal; }

.l-main-container-menus {
  padding: 40px 0 50px 0; }

.l-section {
  width: 100%;
  box-sizing: border-box;
  /* table component */ }
  .l-section + .l-section {
    margin-top: 100px; }
  .l-section .l-section-item > h3 {
    width: 80%;
    margin: 0 auto 50px;
    text-align: center;
    font-size: 26px;
    font-weight: normal; }
  .l-section .l-section-item > h3 ~ p {
    margin-top: -20px;
    margin-bottom: 30px; }
  .l-section .l-section-item > p {
    text-align: center;
    line-height: 1.8; }
  .l-section .l-section-item > .l-section-item-content {
    padding: 0 5%; }
  .l-section.l-section-2column .l-section-item {
    width: 49%;
    float: left;
    box-sizing: border-box; }
  .l-section.l-section-2column .l-section-item + .l-section-item {
    margin-left: 2%; }
  .l-section.l-section-2column .l-section-item .c-brand-list > dd {
    margin-bottom: 15px; }
  .l-section > .l-section-head {
    padding: 20px 0;
    border-top: solid 1px #292929;
    border-bottom: solid 1px #292929;
    margin: 0 0 60px 0;
    text-align: center; }
    .l-section > .l-section-head > p {
      line-height: 1.8; }
  .l-section > .l-section-item + .l-section-item {
    margin-top: 100px;
    margin-bottom: 100px; }
  .l-section h2 {
    margin: 0;
    font-size: 30px;
    line-height: 1.6; }
  .l-section .c-desc > .c-desc-text {
    width: 775px;
    margin-left: auto;
    margin-right: auto; }
    .l-section .c-desc > .c-desc-text > h3 {
      margin: 0 0 20px 0;
      font-size: 24px;
      padding-top: .8em;
      line-height: 1.6; }
    .l-section .c-desc > .c-desc-text > h4 {
      margin: 12px 0 32px 0;
      font-size: 22px;
      text-align: center;
      font-weight: normal; }
    .l-section .c-desc > .c-desc-text > * + h4 {
      margin-top: 40px; }
    .l-section .c-desc > .c-desc-text > p {
      font-size: 16px;
      line-height: 1.7; }
    .l-section .c-desc > .c-desc-text > p small {
      font-size: 90%; }
    .l-section .c-desc > .c-desc-text > dl > dt, .l-section .c-desc > .c-desc-text > dl dd {
      margin: 0; }
    .l-section .c-desc > .c-desc-text > dl > dd {
      margin-left: .5em; }
    .l-section .c-desc > .c-desc-text > dl > dd:before {
      display: inline-block;
      content: "・"; }
    .l-section .c-desc > .c-desc-text > dl > dt + dd, .l-section .c-desc > .c-desc-text > dl dd + dd {
      margin-top: .3em; }
  .l-section .c-desc > .c-desc-figure > img + img {
    margin-top: 24px; }
  .l-section .c-desc > .c-desc-figure-s {
    width: 390px; }
    .l-section .c-desc > .c-desc-figure-s img {
      width: 390px; }
  .l-section .c-desc > .c-desc-figure-m {
    width: 590px; }
    .l-section .c-desc > .c-desc-figure-m img {
      width: 590px; }
  .l-section .c-desc > .c-desc-figure-l {
    width: 890px; }
    .l-section .c-desc > .c-desc-figure-l img {
      width: 890px; }
  .l-section .c-desc + h3 {
    margin-top: 48px; }
  .l-section .c-desc-standalone {
    margin-bottom: 50px; }
    .l-section .c-desc-standalone > .c-desc-text > p {
      font-size: 20px;
      line-height: 1.8;
      text-align: center; }
  .l-section .c-desc-l > .c-desc-text h4, .l-section .c-desc-r > .c-desc-text h4 {
    margin-bottom: 24px;
    text-align: left;
    font-size: 20px; }
  .l-section .c-desc-l > .c-desc-text {
    width: auto; }
  .l-section .c-desc-l > .c-desc-figure {
    float: left; }
  .l-section .c-desc-l > .c-desc-figure-s + .c-desc-text {
    margin-left: 425px; }
  .l-section .c-desc-l > .c-desc-figure-m + .c-desc-text {
    margin-left: 615px; }
  .l-section .c-desc-l > .c-desc-figure-l + .c-desc-text {
    margin-left: 915px; }
  .l-section .c-desc-r > .c-desc-text {
    width: auto; }
  .l-section .c-desc-r > .c-desc-figure {
    float: right; }
  .l-section .c-desc-r > .c-desc-figure-s + .c-desc-text {
    margin-left: 425px; }
  .l-section .c-desc-r > .c-desc-figure-m + .c-desc-text {
    margin-right: 615px; }
  .l-section .c-desc-r > .c-desc-figure-l + .c-desc-text {
    margin-right: 915px; }
  .l-section .c-brand-list {
    width: 100%; }
    .l-section .c-brand-list > dt {
      display: block;
      border-top: solid 1px #292929;
      border-bottom: solid 1px #292929;
      margin: 0 0 30px 0;
      padding: 15px 0;
      text-align: center;
      font-size: 26px; }
    .l-section .c-brand-list > dd {
      display: block;
      float: left;
      margin: 0 0 15px 0; }
    .l-section .c-brand-list.c-brand-list-1column > dd {
      width: 100%; }
    .l-section .c-brand-list.c-brand-list-2column > dd {
      width: 49%;
      margin-bottom: 24px; }
      .l-section .c-brand-list.c-brand-list-2column > dd:nth-child(odd) {
        margin-left: 2%; }
    .l-section .c-brand-list.c-brand-list-3column > dd {
      width: 32%;
      margin-left: 2%;
      margin-bottom: 24px; }
      .l-section .c-brand-list.c-brand-list-3column > dd:nth-child(3n+1) {
        margin-left: 0; }
    .l-section .c-brand-list > dd > a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      color: #fff;
      text-decoration: none;
      cursor: pointer; }
      .l-section .c-brand-list > dd > a:hover {
        opacity: .8; }
    .l-section .c-brand-list > dd > a > img {
      width: 100%;
      height: auto; }
    .l-section .c-brand-list > dd > a > span {
      display: block;
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 10px;
      bottom: 0;
      background-color: rgba(41, 41, 41, 0.7);
      font-size: 16px; }
      .l-section .c-brand-list > dd > a > span:before {
        display: inline-block;
        content: ' ';
        width: 14px;
        height: 11px;
        background-image: url("../images/components/c-nav-list_arrow_w.png");
        background-size: 6px 11px;
        background-repeat: no-repeat; }
    .l-section .c-brand-list.c-brand-list-osaiya > dd,
    .l-section .c-brand-list.c-brand-list-onlinestore > dd,
    .l-section .c-brand-list.c-brand-list-omiyage > dd {
      height: 150px;
      background-repeat: no-repeat;
      background-size: 100%; }
    .l-section .c-brand-list.c-brand-list-bettei > dd {
      height: 315px; }
  .l-section .c-news-list {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .l-section .c-news-list > li {
      width: 228px;
      float: left;
      display: block;
      font-size: 14px;
      line-height: 1.4; }
      .l-section .c-news-list > li > a {
        display: block;
        color: #292929;
        text-decoration: none; }
      .l-section .c-news-list > li > a:hover {
        color: #cf121b;
        text-decoration: underline; }
        .l-section .c-news-list > li > a:hover > .c-news-thumbnail img {
          opacity: .8; }
    .l-section .c-news-list .c-news-thumbnail {
      margin-bottom: 10px; }
      .l-section .c-news-list .c-news-thumbnail > img {
        width: 228px;
        height: 228px; }
    .l-section .c-news-list .c-news-info {
      padding: 0 5px;
      font-size: 13px;
      color: #292929;
      margin-bottom: 3px; }
      .l-section .c-news-list .c-news-info > .c-news-date {
        float: right; }
      .l-section .c-news-list .c-news-info > .c-news-shop {
        float: left;
        position: relative;
        left: -6px; }
    .l-section .c-news-list .c-news-summary {
      padding: 0 5px;
      font-size: 14px;
      line-height: 1.4; }
  .l-section .c-shop-contents-list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .l-section .c-shop-contents-list li {
      display: block;
      float: left;
      margin: 0 0 15px 0;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%; }
    .l-section .c-shop-contents-list li img {
      width: 100%; }
    .l-section .c-shop-contents-list.c-shop-contents-list-1column li {
      width: 100%; }
    .l-section .c-shop-contents-list.c-shop-contents-list-2column li {
      width: 49%; }
      .l-section .c-shop-contents-list.c-shop-contents-list-2column li:nth-child(odd) {
        margin-left: 2%; }
    .l-section .c-shop-contents-list.c-shop-contents-list-3column li {
      width: 32%;
      margin-left: 2%; }
      .l-section .c-shop-contents-list.c-shop-contents-list-3column li:first-child {
        margin-left: 0; }
    .l-section .c-shop-contents-list.c-shop-contents-list-4column li {
      width: 24%;
      margin-left: 1.33%; }
      .l-section .c-shop-contents-list.c-shop-contents-list-4column li:nth-child(4n+1) {
        margin-left: 0; }
    .l-section .c-shop-contents-list li > a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      color: #fff;
      text-decoration: none;
      cursor: pointer; }
      .l-section .c-shop-contents-list li > a:hover {
        opacity: .8; }
    .l-section .c-shop-contents-list li > a > span {
      display: block;
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 10px;
      bottom: 0;
      background-color: rgba(41, 41, 41, 0.7);
      font-size: 16px;
      text-align: center; }
      .l-section .c-shop-contents-list li > a > span:before {
        display: inline-block;
        content: ' ';
        width: 20px;
        height: 11px;
        background-image: url("../images/components/c-nav-list_arrow_down_w.png");
        background-size: 17px 9px;
        background-repeat: no-repeat; }
  .l-section .c-shop-menu-list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .l-section .c-shop-menu-list li {
      display: block;
      float: left;
      margin: 0 0 15px 0;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%; }
    .l-section .c-shop-menu-list li img {
      width: 100%; }
    .l-section .c-shop-menu-list.c-shop-menu-list-1column li {
      width: 100%; }
    .l-section .c-shop-menu-list.c-shop-menu-list-2column li {
      width: 49%; }
      .l-section .c-shop-menu-list.c-shop-menu-list-2column li:nth-child(even) {
        margin-left: 2%; }
    .l-section .c-shop-menu-list.c-shop-menu-list-3column li {
      width: 32%;
      margin-left: 2%; }
      .l-section .c-shop-menu-list.c-shop-menu-list-3column li:first-child {
        margin-left: 0; }
    .l-section .c-shop-menu-list.c-shop-menu-list-4column li {
      width: 24%;
      margin-left: 1.33%; }
      .l-section .c-shop-menu-list.c-shop-menu-list-4column li:nth-child(4n+1) {
        margin-left: 0; }
    .l-section .c-shop-menu-list li > a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      color: #fff;
      text-decoration: none;
      cursor: pointer; }
      .l-section .c-shop-menu-list li > a:hover {
        opacity: .8; }
    .l-section .c-shop-menu-list li > a > span {
      display: block;
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 10px;
      bottom: 0;
      background-color: rgba(41, 41, 41, 0.7);
      font-size: 16px;
      text-align: center; }
      .l-section .c-shop-menu-list li > a > span:before {
        display: inline-block;
        content: ' ';
        width: 20px;
        height: 11px;
        background-image: url("../images/components/c-nav-list_arrow_down_w.png");
        background-size: 17px 9px;
        background-repeat: no-repeat; }
  .l-section .c-table {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 40px; }
    .l-section .c-table tr th {
      padding: 8px 15px 8px;
      font-size: 16px;
      font-weight: bold;
      vertical-align: top;
      text-align: left; }
    .l-section .c-table tr td {
      padding: 8px 15px 8px;
      font-size: 16px; }
  .l-section .c-table dl {
    margin: 0; }
    .l-section .c-table dl dd {
      margin-left: .5em; }
    .l-section .c-table dl + dl {
      margin-top: .5em; }
  .l-section .c-table ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  .l-section .c-table.c-table-companysummary {
    width: 800px;
    margin: 0 auto; }
    .l-section .c-table.c-table-companysummary tr th {
      width: 30%;
      text-align: right; }
    .l-section .c-table.c-table-companysummary tr td {
      padding-left: 10%;
      width: 55%; }
  .l-section .c-table-shopsummary tr th {
    width: 25%;
    padding-left: 0; }
  .l-section .c-table-shopsummary tr td {
    width: auto; }
  .l-section .c-table-shopsummary ~ .c-action-reservation {
    text-align: center;
    margin-top: 40px; }

.l-main-menus {
  background-color: #f2f2f2; }

.l-footer {
  padding: 96px 0 48px 0;
  background-color: #292929;
  color: #ccc; }
  .l-footer .l-footer-list {
    margin: 0;
    padding: 0;
    text-align: center; }
    .l-footer .l-footer-list > li {
      display: inline-block;
      font-size: 14px;
      padding-right: 20px;
      border-right: solid 1px #555; }
    .l-footer .l-footer-list > li a {
      color: #ccc;
      text-decoration: none; }
    .l-footer .l-footer-list > li a:hover {
      text-decoration: underline; }
    .l-footer .l-footer-list > li + li {
      padding-left: 20px; }
    .l-footer .l-footer-list > li:last-child {
      border-right: none;
      padding-right: 0; }
  .l-footer .l-footer-list + .l-footer-list {
    margin-top: 20px; }
  .l-footer .l-copyright {
    margin-top: 95px;
    text-align: center; }
    .l-footer .l-copyright small {
      font-size: 12px; }

.l-section-reservation .l-section-item > h3 {
  width: 80%;
  margin: 0 auto 50px;
  text-align: center;
  font-size: 26px;
  font-weight: normal; }

.l-section-reservation .l-section-item > h3 ~ p {
  margin-top: -20px;
  margin-bottom: 30px; }

.l-section-reservation .l-section-item > p {
  text-align: center;
  line-height: 1.8; }

.l-section.l-section-reservation-action {
  margin-top: 50px; }

.l-notice {
  background: #f3e2e5;
  padding: 10px;
  border-bottom: solid 1px #c11a1a;
  color: #c11a1a;
  font-size: 14px;
  font-family: sans-serif; }

.c-carousel .c-carousel-caption {
  position: absolute;
  width: 90%;
  box-sizing: border-box;
  bottom: 16px;
  left: 5%;
  color: #fff;
  text-shadow: 0 0 3px #808080; }

.c-carousel .c-carousel-caption.c-carousel-caption-top {
  top: 0;
  bottom: initial; }

.c-carousel .c-carousel-caption.c-carousel-caption-right {
  left: initial;
  right: 5%; }

.c-carousel .c-carousel-title {
  font-size: 26px; }

.c-carousel .c-carousel-subtitle {
  font-size: 20px; }

.c-carousel .c-carousel-text {
  font-size: 16px; }
  .c-carousel .c-carousel-text > p {
    margin-top: 12px; }

.c-mainvisual {
  margin-bottom: 40px; }
  .c-mainvisual h1 {
    margin: 0; }
    .c-mainvisual h1 > img {
      width: 100%; }
    .c-mainvisual h1 > span {
      display: none; }

button.c-button {
  border: none; }

.c-button {
  display: inline-block;
  padding: 11px 35px;
  font-size: 20px;
  color: #fff;
  text-decoration: none; }
  .c-button:visited {
    color: #fff; }

.c-button.c-button-primary {
  background-color: #cf121b; }
  .c-button.c-button-primary:before {
    display: inline-block;
    content: ' ';
    width: 20px;
    height: 15px;
    background-image: url("../images/components/c-nav-list_arrow_w.png");
    background-size: 9px 15px;
    background-repeat: no-repeat; }
  .c-button.c-button-primary:hover {
    background-color: #a50e15;
    color: #fff; }

.c-button[disabled], .c-button-disabled {
  background-color: #c1c1c1; }
  .c-button[disabled]:hover, .c-button-disabled:hover {
    background-color: #c1c1c1; }

.c-rsv-steps {
  list-style-type: none;
  margin: 0 auto;
  padding: 0; }
  .c-rsv-steps > li {
    display: inline-block;
    width: 230px;
    border: solid 1px #919191;
    padding: 10px 20px;
    box-sizing: border-box;
    font-size: 20px; }
  .c-rsv-steps > li + li {
    margin-left: 27px; }

.c-rsv-chooses {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  width: 855px; }
  .c-rsv-chooses > li {
    display: block;
    box-sizing: border-box;
    height: 50px;
    float: left;
    border: solid 1px #919191;
    box-sizing: border-box;
    font-size: 20px; }
    .c-rsv-chooses > li > select {
      outline: 0; }
    .c-rsv-chooses > li > i, .c-rsv-chooses > li > span {
      padding-left: 20px;
      font-size: 15px;
      line-height: 50px;
      color: #919191; }
    .c-rsv-chooses > li > span {
      position: relative;
      top: -2px; }
    .c-rsv-chooses > li:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
    .c-rsv-chooses > li:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }
  .c-rsv-chooses > li + li {
    border-left: none; }

.c-rsv-chooses.c-rsv-chooses-shop {
  width: 420px; }

.c-rsv-shop-summary {
  width: 80%;
  margin: 0 auto;
  text-align: center; }
  .c-rsv-shop-summary > dt {
    font-size: 18px;
    font-weight: bold;
    margin: 0; }
  .c-rsv-shop-summary > dd {
    margin: 15px 0 0 0; }

.l-section .c-table.c-table-rsv-entry,
.l-section .c-table.c-table-cnt-entry {
  width: 880px;
  margin: 0 auto; }
  .l-section .c-table.c-table-rsv-entry tr > th,
  .l-section .c-table.c-table-cnt-entry tr > th {
    width: 33%;
    margin-right: 2%;
    padding-top: 10px;
    vertical-align: top; }
  .l-section .c-table.c-table-rsv-entry tr > td,
  .l-section .c-table.c-table-cnt-entry tr > td {
    width: 65%; }

.l-section .c-table.c-table-rsv-confirm tr > th,
.l-section .c-table.c-table-cnt-confirm tr > th {
  width: 43%;
  margin-right: 2%;
  padding-top: 10px;
  text-align: right; }

.l-section .c-table.c-table-rsv-confirm tr > td,
.l-section .c-table.c-table-cnt-confirm tr > td {
  width: 55%; }

.c-table-cell-annotation {
  margin: 8px 0 0 4px; }
  .c-table-cell-annotation > p {
    font-size: 14px;
    line-height: 1.5;
    margin: 4px 0; }

.c-annouce {
  width: 840px;
  margin: 0 auto;
  padding: 0;
  list-style-type: disc;
  list-style-position: inside; }
  .c-annouce > dt {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 22px; }
  .c-annouce > dd {
    font-size: 16px;
    padding: 0;
    line-height: 1.5; }
    .c-annouce > dd:before {
      display: inline;
      content: '・'; }

.c-accessmap {
  width: 590px;
  height: 520px;
  border: 0;
  pointer-events: none; }

.l-section-item.l-section-item-actions {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .l-section-item.l-section-item-actions > .c-link-back {
    margin-top: 15px; }
    .l-section-item.l-section-item-actions > .c-link-back > a, .l-section-item.l-section-item-actions > .c-link-back a:visited {
      font-size: 14px;
      color: #292929;
      text-decoration: none; }
      .l-section-item.l-section-item-actions > .c-link-back > a:hover, .l-section-item.l-section-item-actions > .c-link-back a:visited:hover {
        color: #292929;
        text-decoration: underline; }

.l-section-item.l-centering {
  margin-left: auto;
  margin-right: auto; }

.l-main-container-secondary .l-section-shop-news .c-desc-text > h3 {
  padding-top: .3em;
  font-weight: normal; }

.t-center {
  text-align: center; }

.t-right {
  text-align: right; }

.t-circle-secret {
  display: inline-block;
  padding: 2px 5px;
  margin: 0 2px;
  background-image: url("../images/common/circle_for_secret.svg");
  background-repeat: no-repeat;
  background-size: 26px;
  font-size: 16px;
  color: #cf121b; }

/* form elements */
.f-input-dropdown {
  -webkit-appearance: none;
  border: none;
  padding: 8px 20px;
  background-color: transparent;
  background-image: url("../images/components/c-nav-list_arrow_down_g.png");
  background-size: 11px 6px;
  background-repeat: no-repeat;
  background-position: 98% 50%;
  background-position: right 20px top 54%; }

.f-input-date {
  box-sizing: border-box;
  width: 220px;
  height: 50px; }

.f-input-time {
  box-sizing: border-box;
  width: 130px;
  height: 50px; }

.f-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  height: 50px;
  margin: 0;
  padding: 0 45px 0 20px;
  line-height: 50px;
  background-color: transparent;
  background-image: url("../images/components/c-nav-list_arrow_down_g.png");
  background-size: 11px 6px;
  background-repeat: no-repeat;
  background-position: 98% 50%;
  background-position: right 20px top 50%;
  font-size: 20px;
  outline: 0; }

.f-select::-ms-expand {
  display: none; }

.f-form-entry input[type="text"],
.f-form-entry input[type="email"],
.f-form-entry input[type="tel"],
.f-form-entry textarea {
  font-size: 20px;
  padding: 4px 8px;
  border: solid 1px #ccc; }

.f-form-entry fieldset + fieldset {
  margin-top: 15px; }

.f-form-entry select {
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 8px 45px 8px 8px;
  height: auto;
  line-height: 1.0; }

.f-input-zip {
  width: 100px; }

.f-input-small {
  width: 200px; }

.f-input-medium {
  width: 250px; }

.f-input-large {
  width: 400px; }

.f-textarea-large {
  width: 400px;
  height: 4em; }

.f-textarea-xlarge {
  width: 100%;
  height: 6em; }

/* text modify */
.t-lastorder, .t-annotation {
  font-size: 90%; }

.t-essential {
  color: #f70;
  font-size: 90%; }

.t-alert {
  color: #f70; }

.t-input-value {
  font-size: 16px;
  color: #997814; }
  .t-input-value.t-input-value-large {
    font-size: 20px; }
  .t-input-value.t-input-value-default {
    color: #919191; }

.t-rsv-id {
  font-family: "Helvatica Neue", "Verdana", sans-serif;
  font-size: 40px;
  padding: 0 5px;
  color: #cf121b; }

.t-subtitle {
  font-size: 90%;
  font-weight: normal; }

/* state */
.s-error {
  background-color: #fee; }

.c-invalid-message {
  display: none;
  margin: 15px 0 0;
  font-size: 16px;
  text-align: center;
  color: #f70; }

.c-invalid-message.c-invalid-message-contact {
  display: block;
  text-align: left; }

.c-form-submitting {
  display: none;
  margin: 15px 0 0;
  font-size: 16px;
  text-align: center;
  color: #292929; }

.c-form-back {
  margin-top: 30px; }
  .c-form-back > a {
    text-decoration: none; }

.c-table-rsv-entry .c-invalid-message {
  text-align: left; }

.c-rsv-request-tel {
  margin-top: 5px; }
  .c-rsv-request-tel > dt {
    width: 80px;
    float: left;
    margin: 0 0 10px; }
  .c-rsv-request-tel > dd {
    margin: 0 0 10px; }

.c-partnership-summary-list {
  width: 470px;
  margin: 0 auto 60px;
  padding: 0 0px 0 140px; }

.c-food-list, .c-drink-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .c-food-list > li, .c-drink-list > li {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin: 0 0 15px;
    padding: 5px 15px;
    border-bottom: solid 1px #ccc; }
    .c-food-list > li > .c-food-item-description,
    .c-food-list > li > .c-drink-item-description, .c-drink-list > li > .c-food-item-description,
    .c-drink-list > li > .c-drink-item-description {
      font-size: 14px; }
    .c-food-list > li > .c-food-item-price,
    .c-food-list > li > .c-drink-item-price, .c-drink-list > li > .c-food-item-price,
    .c-drink-list > li > .c-drink-item-price {
      position: absolute;
      display: inline-block;
      right: 0;
      bottom: 5px;
      padding-right: 15px; }

.c-food-list.c-food-list-course > li {
  border-bottom: none;
  text-align: center;
  margin-bottom: 8px; }

.c-desc-l .c-food-list.c-food-list-course li,
.c-desc-r .c-food-list.c-food-list-course li {
  padding-left: 0;
  padding-right: 0;
  text-align: left; }

.c-food-list ~ h4,
.c-drink-list ~ h4 {
  margin: 48px 0 24px 0; }

.c-food-list + .c-food-list,
.c-drink-list + .c-drink-list {
  margin-top: 48px; }

.c-food-list + .c-annotation-list,
.c-drink-list + .c-annotation-list {
  margin-top: 24px; }

.c-annotation-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .c-annotation-list > li {
    font-size: 14px;
    padding: 0;
    margin: 0; }
    .c-annotation-list > li:before {
      display: inline-block;
      content: '※';
      padding-right: 4px; }
  .c-annotation-list > li + li {
    margin-top: 4px; }

.c-desc-bettei .c-annotation-list > li {
  text-align: center; }

.c-desc-bettei .c-food-list li {
  border-bottom: solid 1px #555;
  color: #c1c1c1;
  text-align: center; }

.c-course-price-list {
  width: 960px;
  margin: 0 auto 48px auto;
  padding: 0; }
  .c-course-price-list > dt {
    clear: both;
    float: left;
    box-sizing: border-box;
    width: 510px;
    margin: 0;
    padding: 0 15px 5px 0;
    font-size: 18px;
    text-align: right; }
  .c-course-price-list > dd {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0 0 5px 0;
    font-size: 18px;
    text-align: center; }
  .c-course-price-list > dt + dd {
    float: right;
    width: 450px;
    padding-bottom: 15px;
    text-align: left; }

h3 + .c-course-price-list {
  margin-top: -24px; }

a .fa {
  padding-right: .3em; }

.a-map {
  font-size: 14px;
  color: #292929; }
  .a-map:visited {
    color: #292929; }
  .a-map:hover {
    color: #cf121b; }

.l-container.l-container-subnav {
  margin-top: 48px;
  margin-bottom: 24px; }

.c-mainvisual + .l-container.l-container-subnav {
  margin-top: 0;
  margin-bottom: 24px; }

.l-main-container + .l-container.l-container-subnav {
  margin-top: 24px;
  margin-bottom: 24px; }

.l-container-subnav {
  position: relative; }
  .l-container-subnav > .c-breadcrumb {
    width: 50%;
    float: left;
    position: absolute;
    bottom: 0; }
  .l-container-subnav > .c-sns-share {
    width: 50%;
    float: right;
    min-height: 64px; }

.c-breadcrumb {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 14px; }
  .c-breadcrumb > li {
    display: inline-block;
    vertical-align: bottom; }
    .c-breadcrumb > li > a {
      text-decoration: none; }
    .c-breadcrumb > li > a:hover {
      text-decoration: underline; }
  .c-breadcrumb > li + li:before {
    display: inline;
    content: "\00bb";
    padding: 0px 8px 0 6px; }

/**
 * SNS Share Components
 */
.c-sns-share {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 0;
  text-align: right; }
  .c-sns-share > li {
    display: inline-block;
    margin: 0;
    position: relative;
    bottom: 0; }
  .c-sns-share > li + li {
    margin-left: 8px; }

.c-sns-line-share {
  vertical-align: bottom; }

/**
 * for PC, Tablet
 */
@media (min-width: 768px) {
  .l-container {
    width: 1200px;
    margin: 0 auto; }
    .l-container.l-container-fullwidth {
      width: 100%;
      max-width: 1200px; }
  .l-section .c-news-list > li + li {
    margin-left: 15px; } }
