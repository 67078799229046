/*
 * Web Starter Kit
 *
 * Multi-screen layout styles for your page. Brought to you by the
 * Web Starter Kit team.
 *
 */
@import '_h5bp';

/**
 * Wadaya website version 3
 * application stylesheets
 *
 */
$activeColor: #cf121b;
$normalColor: #292929;
body {
  font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
  color: $normalColor;
  a {
    color: $normalColor;
    &:visited {
      color: $normalColor;
    }
    &:hover {
      color: $activeColor;
    }
  }
}
body.b-bettei {
  background-color: #1b1515;
  color: #f0eeee;
  a {
    color: #f0eeee;
    &:visited {
      color: #f0eeee;
    }
    &:hover {
      color: $activeColor;
    }
  }
}
main {
  display: block;
}
.b-bettei .l-section > .l-section-head {
  border-top: solid 1px #f0eeee;
  border-bottom: solid 1px #f0eeee;
}

.l-header {
  $headerHeight: 70px;
  position: fixed;
  z-index: 100;
  box-sizing: border-box;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  .l-header-nav {
    height: $headerHeight;
  }
  .c-logo {
    display: block;
    float: left;
    width: auto;
    height: $headerHeight;
    margin-left: 27px;
    line-height: 68px;
    img {
      width: auto;
      height: 47px;
    }
  }
  .c-nav-list {
    float: right;
    margin: 0;
    line-height: $headerHeight;
    > li {
      display: inline-block;
      list-style-type: none;
    }
    > li a {
      display: inline-block;
      height: 70px;
      line-height: 70px;
      color: $normalColor;
      text-decoration: none;
    }
    > li a:visited {
      color: $normalColor;
    }
    > li a:hover {
      color: $activeColor;
      text-decoration: underline;
    }
    > li:before {
      display: inline-block;
      content: ' ';
      width: 6px;
      height: 11px;
      padding-left: 6px;
      background-image: url('../images/components/c-nav-list_arrow.png');
      background-size: 6px 11px;
      background-repeat: no-repeat;
    }
    > li.s-nav-list-red:before {
      background-image: url('../images/components/c-nav-list_arrow_r.png');
    }
    > li.c-nav-list-language:before {
      display: none;
    }
    > li + li {
      margin-left: 40px;
    }
    > li + li:last-child {
      margin-right: 40px;
    }
  }
  .c-nav-list-menu {
    display: none;
  }
  .c-nav-list-reservation {
    padding: 0;
  }
}
.l-main {
  padding-top: 72px;
}
.l-main-container {
  padding-bottom: 60px;
}
.l-main-container + .l-main-container {
  padding-top: 60px;
}
.l-main-container.l-main-container-secondary {
  background-color: #f2f2f2;
  padding-bottom: 60px;
  .l-section {
    > .l-section-head {
      border: none;
      padding: 0;
    }
    > .l-section-item + a[name] + .l-section-item {
      border-top: solid 1px #fff;
      margin-top: 60px;
      padding-top: 60px;
    }
    h2 {
      font-weight: normal;
    }
  }
}
.l-main-container-menus {
  padding: 40px 0 50px 0;
  // background-color: #f2f2f2;
}

.l-section {
  width: 100%;
  box-sizing: border-box;
  & + .l-section {
    margin-top: 100px;
  }
  & .l-section-item {
    > h3 {
      width: 80%;
      margin: 0 auto 50px;
      text-align: center;
      font-size: 26px;
      font-weight: normal;
    }
    > h3 ~ p {
      margin-top: -20px;
      margin-bottom: 30px;
    }
    > p {
      text-align: center;
      line-height: 1.8;
    }
    > .l-section-item-content {
      padding: 0 5%;
    }
  }
  &.l-section-2column {
    .l-section-item {
      width: 49%;
      float: left;
      box-sizing: border-box;
    }
    .l-section-item + .l-section-item {
      margin-left: 2%;
    }
    .l-section-item .c-brand-list > dd {
      margin-bottom: 15px;
    }
  }
  > .l-section-head {
    padding: 20px 0;
    border-top: solid 1px $normalColor;
    border-bottom: solid 1px $normalColor;
    margin: 0 0 60px 0;
    text-align: center;
    > p {
      line-height: 1.8;
    }
  }
  > .l-section-item + .l-section-item {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  h2 {
    margin: 0;
    font-size: 30px;
    line-height: 1.6;
  }
  .c-desc {
    > .c-desc-text {
      width: 775px;
      margin-left: auto;
      margin-right: auto;
      > h3 {
        margin: 0 0 20px 0;
        font-size: 24px;
        padding-top: .8em;
        line-height: 1.6;
      }
      > h4 {
        margin: 12px 0 32px 0;
        font-size: 22px;
        text-align: center;
        font-weight: normal;
      }
      > * + h4 {
        margin-top: 40px;
      }
      > p {
        font-size: 16px;
        line-height: 1.7;
      }
      > p small {
        font-size: 90%;
      }
      > dl {
        > dt, dd {
          margin: 0;
        }
        > dd {
          margin-left: .5em;
        }
        > dd:before {
          display: inline-block;
          content: "・";
        }
        > dt + dd, dd + dd {
          margin-top: .3em;
        }
      }
    }
    > .c-desc-figure {
      > img + img {
        margin-top: 24px;
      }
    }
    > .c-desc-figure-s {
      width: 390px;
      img {
        width: 390px;
      }
    }
    > .c-desc-figure-m {
      width: 590px;
      img {
        width: 590px;
      }
    }
    > .c-desc-figure-l {
      width: 890px;
      img {
        width: 890px;
      }
    }
    & + h3 {
      margin-top: 48px;
    }
  }
  .c-desc-standalone {
    > .c-desc-text {
      > p {
        font-size: 20px;
        line-height: 1.8;
        text-align: center;
      }
    }
    margin-bottom: 50px;
  }
  .c-desc-l, .c-desc-r {
    > .c-desc-text h4 {
      margin-bottom: 24px;
      text-align: left;
      font-size: 20px;
    }
  }
  .c-desc-l {
    > .c-desc-text {
      width: auto;
    }
    > .c-desc-figure {
      float: left;
    }
    > .c-desc-figure-s + .c-desc-text {
      margin-left: 425px;
    }
    > .c-desc-figure-m + .c-desc-text {
      margin-left: 615px;
    }
    > .c-desc-figure-l + .c-desc-text {
      margin-left: 915px;
    }
  }
  .c-desc-r {
    > .c-desc-text {
      width: auto;
    }
    > .c-desc-figure {
      float: right;
    }
    > .c-desc-figure-s + .c-desc-text {
      margin-left: 425px;
    }
    > .c-desc-figure-m + .c-desc-text {
      margin-right: 615px;
    }
    > .c-desc-figure-l + .c-desc-text {
      margin-right: 915px;
    }
  }
  .c-brand-list {
    width: 100%;
    > dt {
      display: block;
      border-top: solid 1px $normalColor;
      border-bottom: solid 1px $normalColor;
      margin: 0 0 30px 0;
      padding: 15px 0;
      text-align: center;
      font-size: 26px;
    }
    > dd {
      display: block;
      float: left;
      margin: 0 0 15px 0;
    }
    &.c-brand-list-1column > dd {
      width: 100%;
    }
    &.c-brand-list-2column > dd {
      width: 49%;
      margin-bottom: 24px;
      &:nth-child(odd) {
        margin-left: 2%;
      }
    }
    &.c-brand-list-3column > dd {
      width: 32%;
      margin-left: 2%;
      margin-bottom: 24px;
      &:nth-child(3n+1) {
        margin-left: 0;
      }
    }
    > dd > a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
    > dd > a > img {
      width: 100%;
      height: auto;
    }
    > dd > a > span {
      display: block;
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 10px;
      bottom: 0;
      background-color: rgba(41, 41, 41, .7);
      font-size: 16px;
      &:before {
        display: inline-block;
        content: ' ';
        width: 14px;
        height: 11px;
        background-image: url('../images/components/c-nav-list_arrow_w.png');
        background-size: 6px 11px;
        background-repeat: no-repeat;
      }
    }

    &.c-brand-list-osaiya > dd,
    &.c-brand-list-onlinestore > dd,
    &.c-brand-list-omiyage > dd {
      height: 150px;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    &.c-brand-list-bettei > dd {
      height: 315px;
    }
  }
  .c-news-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    > li {
      width: 228px;
      float: left;
      display: block;
      font-size: 14px;
      line-height: 1.4;
      > a {
        display: block;
        color: $normalColor;
        text-decoration: none;
      }
      > a:hover {
        color: $activeColor;
        text-decoration: underline;
        > .c-news-thumbnail img {
          opacity: .8;
        }
      }
    }
    .c-news-thumbnail {
      margin-bottom: 10px;
      > img {
        width: 228px;
        height: 228px;
      }
    }
    .c-news-info {
      padding: 0 5px;
      font-size: 13px;
      color: $normalColor;
      > .c-news-date {
        float: right;
      }
      > .c-news-shop {
        float: left;
        position: relative;
        left: -6px;
      }
      margin-bottom: 3px;
    }
    .c-news-summary {
      padding: 0 5px;
      font-size: 14px;
      line-height: 1.4;
    }
  }
  .c-shop-contents-list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: block;
      float: left;
      margin: 0 0 15px 0;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    li img {
      width: 100%;
    }
    &.c-shop-contents-list-1column li {
      width: 100%;
    }
    &.c-shop-contents-list-2column li {
      width: 49%;
      &:nth-child(odd) {
        margin-left: 2%;
      }
    }
    &.c-shop-contents-list-3column li {
      width: 32%;
      margin-left: 2%;
      &:first-child {
        margin-left: 0;
      }
    }
    &.c-shop-contents-list-4column li {
      width: 24%;
      margin-left: 1.33%;
      &:nth-child(4n+1) {
        margin-left: 0;
      }
    }
    li > a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
    li > a > span {
      display: block;
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 10px;
      bottom: 0;
      background-color: rgba(41, 41, 41, .7);
      font-size: 16px;
      text-align: center;
      &:before {
        display: inline-block;
        content: ' ';
        width: 20px;
        height: 11px;
        background-image: url('../images/components/c-nav-list_arrow_down_w.png');
        background-size: 17px 9px;
        background-repeat: no-repeat;
      }
    }
  }
  .c-shop-menu-list {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: block;
      float: left;
      margin: 0 0 15px 0;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    li img {
      width: 100%;
    }
    &.c-shop-menu-list-1column li {
      width: 100%;
    }
    &.c-shop-menu-list-2column li {
      width: 49%;
      &:nth-child(even) {
        margin-left: 2%;
      }
    }
    &.c-shop-menu-list-3column li {
      width: 32%;
      margin-left: 2%;
      &:first-child {
        margin-left: 0;
      }
    }
    &.c-shop-menu-list-4column li {
      width: 24%;
      margin-left: 1.33%;
      &:nth-child(4n+1) {
        margin-left: 0;
      }
    }
    li > a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
    li > a > span {
      display: block;
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 10px;
      bottom: 0;
      background-color: rgba(41, 41, 41, .7);
      font-size: 16px;
      text-align: center;
      &:before {
        display: inline-block;
        content: ' ';
        width: 20px;
        height: 11px;
        background-image: url('../images/components/c-nav-list_arrow_down_w.png');
        background-size: 17px 9px;
        background-repeat: no-repeat;
      }
    }
  }
  /* table component */
  .c-table {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 40px;
    tr th {
      padding: 8px 15px 8px;
      font-size: 16px;
      font-weight: bold;
      vertical-align: top;
      text-align: left;
    }
    tr td {
      padding: 8px 15px 8px;
      font-size: 16px;
    }
  }
  .c-table dl {
    margin: 0;
    dd {
      margin-left: .5em;
    }
    & + dl {
      margin-top: .5em;
    }
  }
  .c-table ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .c-table.c-table-companysummary {
    width: 800px;
    margin: 0 auto;
    tr th {
      width: 30%;
      text-align: right;
    }
    tr td {
      padding-left: 10%;
      width: 55%;
    }
  }
  .c-table-shopsummary {
    tr th {
      width: 25%;
      padding-left: 0;
    }
    tr td {
      width: auto;
    }
  }
  .c-table-shopsummary ~ .c-action-reservation {
    text-align: center;
    margin-top: 40px;
  }
}
.l-main-menus {
  background-color: #f2f2f2;
}
.l-footer {
  padding: 96px 0 48px 0;
  background-color: $normalColor;
  color: #ccc;
  .l-footer-list {
    margin: 0;
    padding: 0;
    text-align: center;
    > li {
      display: inline-block;
      font-size: 14px;
      padding-right: 20px;
      border-right: solid 1px #555;
    }
    > li a {
      color: #ccc;
      text-decoration: none;
    }
    > li a:hover {
      text-decoration: underline;
    }
    > li + li {
      padding-left: 20px;
    }
    > li:last-child {
      border-right: none;
      padding-right: 0;
    }
  }
  .l-footer-list + .l-footer-list {
    margin-top: 20px;
  }
  .l-copyright {
    margin-top: 95px;
    text-align: center;
    small {
      font-size: 12px;
    }
  }
}
.l-section-reservation {
  .l-section-item > h3 {
    width: 80%;
    margin: 0 auto 50px;
    // padding: 0 0 15px;
    // border-bottom: solid 1px $normalColor;
    text-align: center;
    font-size: 26px;
    font-weight: normal;
  }
  .l-section-item > h3 ~ p {
    margin-top: -20px;
    margin-bottom: 30px;
  }
  .l-section-item > p {
    text-align: center;
    line-height: 1.8;
  }
}
.l-section.l-section-reservation-action {
  margin-top: 50px;
}
.l-notice {
  background: #f3e2e5;
  padding: 10px;
  border-bottom: solid 1px #c11a1a;
  color: #c11a1a;
  font-size: 14px;
  font-family: sans-serif;
}

.c-carousel {
  .c-carousel-caption {
    position: absolute;
    width: 90%;
    box-sizing: border-box;
    bottom: 16px;
    left: 5%;
    color: #fff;
    text-shadow: 0 0 3px #808080;
  }
  .c-carousel-caption.c-carousel-caption-top {
    top: 0;
    bottom: initial;
  }
  .c-carousel-caption.c-carousel-caption-right {
    left: initial;
    right: 5%;
  }
  .c-carousel-title {
    font-size: 26px;
  }
  .c-carousel-subtitle {
    font-size: 20px;
  }
  .c-carousel-text {
    font-size: 16px;
    > p {
      margin-top: 12px;
    }
  }
}



.c-mainvisual {
  h1 {
    margin: 0;
    > img {
      width: 100%;
    }
    > span {
      display: none;
    }
  }
  margin-bottom: 40px;
}

button.c-button {
  border: none;
}
.c-button {
  display: inline-block;
  padding: 11px 35px;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  &:visited {
    color: #fff;
  }
}
.c-button.c-button-primary {
  background-color: $activeColor;
  &:before {
    display: inline-block;
    content: ' ';
    width: 20px;
    height: 15px;
    background-image: url('../images/components/c-nav-list_arrow_w.png');
    background-size: 9px 15px;
    background-repeat: no-repeat;
  }
  &:hover {
    background-color: darken($activeColor, 9%);
    color: #fff;
  }
}
.c-button[disabled], .c-button-disabled {
  background-color: #c1c1c1;
  &:hover {
    background-color: #c1c1c1;
  }
}
.c-rsv-steps {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  > li {
    display: inline-block;
    width: 230px;
    border: solid 1px #919191;
    padding: 10px 20px;
    box-sizing: border-box;
    font-size: 20px;
  }
  > li + li {
    margin-left: 27px;
  }
}
.c-rsv-chooses {
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  width: 855px;
  > li {
    display: block;
    box-sizing: border-box;
    height: 50px;
    float: left;
    border: solid 1px #919191;
    box-sizing: border-box;
    font-size: 20px;
    > select {
      outline: 0;
    }
    > i, > span {
      padding-left: 20px;
      font-size: 15px;
      line-height: 50px;
      color: #919191;
    }
    > span {
      position: relative;
      top: -2px;
    }
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  > li + li {
    border-left: none;
  }
}
.c-rsv-chooses.c-rsv-chooses-shop {
  width: 420px;
}
.c-rsv-shop-summary {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  > dt {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }
  > dd {
    margin: 15px 0 0 0;
  }
}
.l-section .c-table.c-table-rsv-entry,
.l-section .c-table.c-table-cnt-entry {
  width: 880px;
  margin: 0 auto;
  tr {
    > th {
      width: 33%;
      margin-right: 2%;
      padding-top: 10px;
      vertical-align: top;
    }
    > td {
      width: 65%;
    }
  }
}
.l-section .c-table.c-table-rsv-confirm,
.l-section .c-table.c-table-cnt-confirm {
  tr {
    > th {
      width: 43%;
      margin-right: 2%;
      padding-top: 10px;
      text-align: right;
    }
    > td {
      width: 55%;
    }
  }
}
.c-table-cell-annotation {
  margin: 8px 0 0 4px;
  > p {
    font-size: 14px;
    line-height: 1.5;
    margin: 4px 0;
  }
}
.c-annouce {
  width: 840px;
  margin: 0 auto;
  padding: 0;
  list-style-type: disc;
  list-style-position: inside;
  > dt {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 22px;
  }
  > dd {
    &:before {
      display: inline;
      content: '・';
    }
    font-size: 16px;
    padding: 0;
    line-height: 1.5;
  }
}
.c-accessmap {
  width: 590px;
  height: 520px;
  border: 0;
  pointer-events: none;
}

.l-section-item.l-section-item-actions {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  > .c-link-back {
    margin-top: 15px;
    > a, a:visited {
      font-size: 14px;
      color: $normalColor;
      text-decoration: none;
      &:hover {
        color: $normalColor;
        text-decoration: underline;
      }
    }
  }
}
.l-section-item.l-centering {
  margin-left: auto;
  margin-right: auto;
}
.l-main-container-secondary .l-section-shop-news {
  .c-desc-text > h3 {
    padding-top: .3em;
    font-weight: normal;
  }
}

.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}
.t-circle-secret {
  display: inline-block;
  padding: 2px 5px;
  margin: 0 2px;
  background-image: url("../images/common/circle_for_secret.svg");
  background-repeat: no-repeat;
  background-size: 26px;
  font-size: 16px;
  color: #cf121b;
}
/* form elements */
.f-input-dropdown {
  -webkit-appearance: none;
  border: none;
  padding: 8px 20px;
  background-color: transparent;
  background-image: url('../images/components/c-nav-list_arrow_down_g.png');
  background-size: 11px 6px;
  background-repeat: no-repeat;
  background-position: 98% 50%;
  background-position: right 20px top 54%;
}
.f-input-date {
  box-sizing: border-box;
  width: 220px;
  height: 50px;
}
.f-input-time {
  box-sizing: border-box;
  width: 130px;
  height: 50px;
}
.f-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  height: 50px;
  margin: 0;
  padding: 0 45px 0 20px;
  line-height: 50px;
  background-color: transparent;
  background-image: url('../images/components/c-nav-list_arrow_down_g.png');
  background-size: 11px 6px;
  background-repeat: no-repeat;
  background-position: 98% 50%;
  background-position: right 20px top 50%;
  font-size: 20px;
  outline: 0;
}
.f-select::-ms-expand {
  display: none;
}
.f-form-entry {
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea, {
    font-size: 20px;
    padding: 4px 8px;
    border: solid 1px #ccc;
  }
  fieldset + fieldset {
    margin-top: 15px;
  }
  select {
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 8px 45px 8px 8px;
    height: auto;
    line-height: 1.0;
  }
}
.f-input-zip {
  width: 100px;
}
.f-input-small {
  width: 200px;
}
.f-input-medium {
  width: 250px;
}
.f-input-large {
  width: 400px;
}
.f-textarea-large {
  width: 400px;
  height: 4em;
}
.f-textarea-xlarge {
  width: 100%;
  height: 6em;
}

/* text modify */
.t-lastorder, .t-annotation {
  font-size: 90%;
}
.t-essential {
  color: #f70;
  font-size: 90%;
}
.t-alert {
  color: #f70;
}
.t-input-value {
  font-size: 16px;
  color: #997814;
  &.t-input-value-large {
    font-size: 20px;
  }
  &.t-input-value-default {
    color: #919191;
  }
}
.t-rsv-id {
  font-family: "Helvatica Neue", "Verdana", sans-serif;
  font-size: 40px;
  padding: 0 5px;
  color: $activeColor;
}
.t-subtitle {
  font-size: 90%;
  font-weight: normal;
}
/* state */
.s-error {
  background-color: #fee;
}
.c-invalid-message {
  display: none;
  margin: 15px 0 0;
  font-size: 16px;
  text-align: center;
  color: #f70;
}
.c-invalid-message.c-invalid-message-contact {
  display: block;
  text-align: left;
}
.c-form-submitting {
  display: none;
  margin: 15px 0 0;
  font-size: 16px;
  text-align: center;
  color: $normalColor;
}
.c-form-back {
  margin-top: 30px;
  & > a {
    text-decoration: none;
  }
}
.c-table-rsv-entry .c-invalid-message {
  text-align: left;
}
.c-rsv-request-tel {
  margin-top: 5px;
  > dt {
    width: 80px;
    float: left;
    margin: 0 0 10px;
  }
  > dd {
    margin: 0 0 10px;
  }
}
.c-partnership-summary-list {
  width: 470px;
  margin: 0 auto 60px;
  padding: 0 0px 0 140px;
}

.c-food-list, .c-drink-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  > li {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin: 0 0 15px;
    padding: 5px 15px;
    border-bottom: solid 1px #ccc;
    > .c-food-item-description,
    > .c-drink-item-description {
      font-size: 14px;
    }
    > .c-food-item-price,
    > .c-drink-item-price {
      position: absolute;
      display: inline-block;
      right: 0;
      bottom: 5px;
      padding-right: 15px;
    }
  }
}

.c-food-list.c-food-list-course {
  > li {
    border-bottom: none;
    text-align: center;
    margin-bottom: 8px;
  }
}
.c-desc-l .c-food-list.c-food-list-course li,
.c-desc-r .c-food-list.c-food-list-course li {
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

.c-food-list ~ h4,
.c-drink-list ~ h4 {
  margin: 48px 0 24px 0;
}
.c-food-list + .c-food-list,
.c-drink-list + .c-drink-list {
  margin-top: 48px;
}
.c-food-list + .c-annotation-list,
.c-drink-list + .c-annotation-list {
  margin-top: 24px;
}
.c-annotation-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  > li {
    font-size: 14px;
    padding: 0;
    margin: 0;
    &:before {
      display: inline-block;
      content: '※';
      padding-right: 4px;
    }
  }
  > li + li {
    margin-top: 4px;
  }
}
.c-desc-bettei .c-annotation-list > li {
  text-align: center;
}
.c-desc-bettei .c-food-list li {
  border-bottom: solid 1px #555;
  color: #c1c1c1;
  text-align: center;
}
.c-course-price-list {
  width: 960px;
  margin: 0 auto 48px auto;
  padding: 0;
  > dt {
    clear: both;
    float: left;
    box-sizing: border-box;
    width: 510px;
    margin: 0;
    padding: 0 15px 5px 0;
    font-size: 18px;
    text-align: right;
  }
  > dd {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0 0 5px 0;
    font-size: 18px;
    text-align: center;
  }
  > dt + dd {
    float: right;
    width: 450px;
    padding-bottom: 15px;
    text-align: left;
  }
}
h3 + .c-course-price-list {
  margin-top: -24px;
}

a .fa {
  padding-right: .3em;
}
.a-map {
  font-size: 14px;
  color: $normalColor;
  &:visited {
    color: $normalColor;
  }
  &:hover {
    color: $activeColor;
  }
}

.l-container.l-container-subnav {
  margin-top: 48px;
  margin-bottom: 24px;
}
.c-mainvisual + .l-container.l-container-subnav {
  margin-top: 0;
  margin-bottom: 24px;
}
.l-main-container + .l-container.l-container-subnav {
  margin-top: 24px;
  margin-bottom: 24px;
}
.l-container-subnav {
  position: relative;
  > .c-breadcrumb {
    width: 50%;
    float: left;
    position: absolute;
    bottom: 0;
  }
  > .c-sns-share {
    width: 50%;
    float: right;
    min-height: 64px;
  }
}
.c-breadcrumb {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 14px;
  > li {
    display: inline-block;
    vertical-align: bottom;
    > a {
      text-decoration: none;
    }
    > a:hover {
      text-decoration: underline;
    }
  }
  > li + li:before {
    display: inline;
    content: "\00bb";
    padding: 0px 8px 0 6px;
  }
}
/**
 * SNS Share Components
 */
.c-sns-share {
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 0;
  text-align: right;
  > li {
    display: inline-block;
    margin: 0;
    position: relative;
    bottom: 0;
  }
  > li + li {
    margin-left: 8px;
  }
}
.c-sns-line-share {
  vertical-align: bottom;
}

/**
 * for PC, Tablet
 */
@media (min-width: 768px) {
  .l-container {
    width: 1200px;
    margin: 0 auto;
    &.l-container-fullwidth {
      width: 100%;
      max-width: 1200px;
    }
  }
  .l-section {
    .c-news-list {
      > li + li {
        margin-left: 15px;
      }
    }
  }
}
